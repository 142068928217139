import styled from 'styled-components';
import { default as NotStyledContainer } from 'styles/elements/container';
import { default as NotStyledTitle } from 'styles/elements/title';

const Container = styled(NotStyledContainer)`
`;

const Wrapper = styled.section`
`;

const Title = styled(NotStyledTitle)`
  margin: 0;
  font-family: 'Gruppo', sans-serif;
  max-width: 1070px;
  font-weight: 400;
  font-size: 60px;
  line-height: 65px;
  color: #000000;
  @media (max-width: 600px) {
    font-size: 35px;
    line-height: 40px;
  }
`;

export default {
  Container,
  Title,
  Wrapper,
};

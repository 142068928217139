const NewsSlides = [
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2024-06-25T00:00:00Z',
    text: 'LayerZero ($ZRO) is now on Roobee.finance!',
    link: 'https://medium.com/roobee-invest/join-the-kucoin-ama-with-roobee-1-941-700-roobee-to-give-away-49d121451f78',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2024-06-13T00:00:00Z',
    text: 'Unique Roobee X Scroll NFT!',
    link: 'https://medium.com/roobee-invest/join-the-kucoin-ama-with-roobee-1-941-700-roobee-to-give-away-49d121451f78',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2024-06-04T00:00:00Z',
    text: 'Updated Roobee Ecosystem!',
    link: 'https://medium.com/roobee-invest/join-the-kucoin-ama-with-roobee-1-941-700-roobee-to-give-away-49d121451f78',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2024-05-07T00:00:00Z',
    text: 'Universal Investment Strategy',
    link: 'https://medium.com/roobee-invest/join-the-kucoin-ama-with-roobee-1-941-700-roobee-to-give-away-49d121451f78',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2024-04-25T00:00:00Z',
    text: 'Blast is now available on Roobee.finance!',
    link: 'https://medium.com/roobee-invest/join-the-kucoin-ama-with-roobee-1-941-700-roobee-to-give-away-49d121451f78',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2024-03-05T00:00:00Z',
    text: 'We’re starting to add support for RWA assets on Roobee.finance!',
    link: 'https://medium.com/roobee-invest/join-the-kucoin-ama-with-roobee-1-941-700-roobee-to-give-away-49d121451f78',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2024-02-28T00:00:00Z',
    text: 'Roobee Community on Lens!',
    link: 'https://medium.com/roobee-invest/join-the-kucoin-ama-with-roobee-1-941-700-roobee-to-give-away-49d121451f78',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2024-02-12T00:00:00Z',
    text: 'Launching the Loyalty Program on Galxe!',
    link: 'https://medium.com/roobee-invest/join-the-kucoin-ama-with-roobee-1-941-700-roobee-to-give-away-49d121451f78',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2024-01-14T00:00:00Z',
    text: 'Join the KuCoin AMA With ROOBEE, 1,941,700 ROOBEE to Give Away!',
    link: 'https://medium.com/roobee-invest/join-the-kucoin-ama-with-roobee-1-941-700-roobee-to-give-away-49d121451f78',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2024-12-11T00:00:00Z',
    text: 'Join the #AMA with Roobee and Gate! $1000 giveaway!',
    link: 'https://medium.com/roobee-invest/join-the-ama-with-roobee-and-gate-1000-giveaway-b6d7f3337be8',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2024-12-07T00:00:00Z',
    text: 'We have an update',
    link: 'https://medium.com/roobee-invest/we-have-an-update-2c5b81ad5c61',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2023-11-14T00:00:00Z',
    text: 'Exchange ROOBEE in your Binance WEB3 wallet!',
    link: 'https://medium.com/roobee-invest/exchange-roobee-in-your-binance-web3-wallet-c364d40fa6f6',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2023-10-26T00:00:00Z',
    text: 'Scroll — New Vote on Roobee DAO!',
    link: 'https://medium.com/roobee-invest/scroll-new-vote-on-roobee-dao-79d42909d9ed',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2023-09-19T00:00:00Z',
    text: 'The ROOBEE token — where and how to use it?',
    link: 'https://medium.com/@roobee_invest/the-roobee-token-where-and-how-to-use-it-b43c472100dd',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2023-09-12T00:00:00Z',
    text: 'We’ve added support for Gnosis Chain to Roobee.finance!',
    link: 'https://medium.com/roobee-invest/weve-added-support-for-gnosis-chain-to-roobee-finance-2789f76101c0',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2023-09-08T00:00:00Z',
    text: 'We’ve added support for Linea to Roobee.finance!',
    link: 'https://medium.com/roobee-invest/weve-added-support-for-linea-to-roobee-finance-58ec750623e',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2023-08-31T00:00:00Z',
    text: 'opBNB — New Vote on Roobee DAO!',
    link: 'https://medium.com/roobee-invest/opbnb-new-vote-on-roobee-dao-81b2ce86906e',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2023-08-17T00:00:00Z',
    text: 'New Update on Roobee.finance — Introducing the Marketplace!',
    link: 'https://medium.com/roobee-invest/new-update-on-roobee-finance-introducing-the-marketplace-7f09d0940601',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2023-07-25T00:00:00Z',
    text: 'Roobee Community on DeBank!',
    link: 'https://medium.com/roobee-invest/roobee-community-on-debank-cf4aa07dc105',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2023-07-18T00:00:00Z',
    text: 'Upcoming Updates for Roobee.finance!',
    link: 'https://medium.com/roobee-invest/upcoming-updates-for-roobee-finance-cbcb4162b58c',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2023-07-06T00:00:00Z',
    text: 'We’ve added support for zkSync Era to Roobee.finance!',
    link: 'https://medium.com/roobee-invest/weve-added-support-for-zksync-era-to-roobee-finance-bd57283132b4',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2023-05-23T00:00:00Z',
    text: 'We’ve got an update!',
    link: 'https://medium.com/roobee-invest/weve-got-an-update-9718cf36be54',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2023-04-27T00:00:00Z',
    text: 'Roobee is now on Zealy!',
    link: 'https://medium.com/roobee-invest/roobee-is-now-on-zealy-3397a7da8f8c',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2023-04-20T00:00:00Z',
    text: 'The testing for the Roobee DCA module is now live!',
    link: 'https://medium.com/roobee-invest/the-testing-for-the-roobee-dca-module-is-now-live-425aa187d3e2',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2023-04-03T00:00:00Z',
    text: 'What is Roobee: the platform’s DEcentralized part',
    link: 'https://medium.com/roobee-invest/what-is-roobee-the-platforms-decentralized-part-451d77ccdea7',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2023-03-13T00:00:00Z',
    text: 'What is Roobee: the platform’s centralized part',
    link: 'https://roobee-rus.medium.com/r-4dd3fc348f6b',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2023-02-23T00:00:00Z',
    text: 'The testing for the Roobee DCA module goes live soon!',
    link: 'https://medium.com/roobee-invest/the-testing-for-the-roobee-dca-module-goes-live-soon-88b06e9c43c6',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2023-02-03T00:00:00Z',
    text: 'The DCA module testing whitelist is now open!',
    link: 'https://medium.com/roobee-invest/the-dca-module-testing-whitelist-is-now-open-d6c820592af',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2023-01-31T00:00:00Z',
    text: 'Roobee’s official Discord community is now live!',
    link: 'https://medium.com/roobee-invest/roobees-official-discord-community-is-now-live-e7667059f37b',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2023-01-27T00:00:00Z',
    text: 'We’ve added support for Bitgert to Roobee.finance!',
    link: 'https://medium.com/roobee-invest/weve-added-support-for-bitgert-to-roobee-finance-65fb8be47220',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2023-01-05T00:00:00Z',
    text: 'Roobee’s 2022 in Review',
    link: 'https://medium.com/@roobee_invest/roobees-2022-in-review-14ff418bf46a',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2022-12-29T00:00:00Z',
    text: 'AVAX is already available on Roobee.finance!',
    link: 'https://medium.com/roobee-invest/avax-is-already-available-on-roobee-finance-5f17c89b6717',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2022-10-18T00:00:00Z',
    text: 'Stake ROOBEE and farm BANANA!',
    link: 'https://medium.com/roobee-invest/stake-roobee-and-farm-banana-ddd74c239cdf',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2022-09-29T00:00:00Z',
    text: 'Roobee.finance has been added to DappRadar!',
    link: 'https://medium.com/roobee-invest/roobee-finance-has-been-added-to-dappradar-5dabe4d8cc08',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2022-09-26T00:00:00Z',
    text: 'We’ve added support for MathWallet to Roobee.finance!',
    link: 'https://medium.com/roobee-invest/weve-added-support-for-mathwallet-to-roobee-finance-dad6efb202e3',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2022-07-26T00:00:00Z',
    text: 'We’ve added support for ONTO Wallet to Roobee.finance',
    link: 'https://medium.com/roobee-invest/weve-added-support-for-onto-wallet-to-roobee-finance-9eeafcc7bf8c',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2022-07-07T00:00:00Z',
    text: 'Roobee has partnered with O3 Labs',
    link: 'https://medium.com/roobee-invest/roobee-has-partnered-with-o3-labs-d23270676e6f',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2022-07-05T00:00:00Z',
    text: 'KuCoin has added ROOBEE into its DeFi section',
    link: 'https://medium.com/roobee-invest/kucoin-has-added-roobee-into-its-defi-section-e893b68498d8',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2022-06-14T00:00:00Z',
    text: 'A partnership BitKeep',
    link: 'https://medium.com/roobee-invest/a-partnership-bitkeep-5e3f0882777b',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2022-04-21T00:00:00Z',
    text: 'Making the ROOBEE token compatible with the Polygon, Avalanche, Optimism, and Arbitrum networks.',
    link: 'https://medium.com/@roobee_invest/a-new-voting-round-on-roobee-dao-9abe2cc5f64b',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2022-04-12T00:00:00Z',
    text: 'Key functionalities of Roobee.finance',
    link: 'https://medium.com/roobee-invest/key-functionalities-of-roobee-finance-84a96d6552eb',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2022-03-18T00:00:00Z',
    text: 'Adding support for the Ethereum, Polygon, Avalanche, Optimism, and A'
      + 'rbitrum networks to the ‘Invest’ section of Roobee.finance.',
    link: 'https://medium.com/roobee-invest/a-new-voting-round-on-roobee-dao-30eafea99f3a',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2022-03-05T00:00:00Z',
    text: 'New tokens in the ‘Invest’ section of Roobee.finance!',
    link: 'https://medium.com/roobee-invest/new-tokens-in-the-invest-section-of-roobee-finance-f0dec02e0bbd',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2022-02-23T00:00:00Z',
    text: 'Doge tokens on Roobee.finance: cast your vote on Roobee DAO!',
    link: 'https://medium.com/roobee-invest/doge-tokens-on-roobee-finance-cast-your-vote-on-roobee-dao-d604ed97c281',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2022-02-14T00:00:00Z',
    text: 'We’ve added support for Avalanche (C-Chain) to Roobee.finance!',
    link: 'https://medium.com/roobee-invest/weve-added-support-for-avalanche-c-chain-to-roobee-finance-7b20375bd1d3',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2022-02-01T00:00:00Z',
    text: 'Uniswap V3 is now integrated into Roobee.Finance',
    link: 'https://medium.com/roobee-invest/uniswap-v3-is-now-integrated-into-roobee-finance-a91d8aa44617',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2022-01-27T00:00:00Z',
    text: 'We’ve added support for Polygon to Roobee.finance!',
    link: 'https://medium.com/roobee-invest/weve-added-support-for-polygon-to-roobee-finance-7858bd1e164f',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2022-01-27T00:00:00Z',
    text: 'We’ve added support for Arbitrum to Roobee.finance!',
    link: 'https://medium.com/roobee-invest/weve-added-support-for-arbitrum-to-roobee-finance-dbe322a5071b',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2021-11-09T00:00:00Z',
    text: 'Klarna’s pre-IPO is now available on the Roobee platform!',
    link: 'https://medium.com/roobee-invest/klarnas-pre-ipo-is-now-available-on-the-roobee-platform-5950fe556f83',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2021-10-22T00:00:00Z',
    text: 'Roobee announces a partnership with Polars!',
    link: 'https://medium.com/roobee-invest/roobee-announces-a-partnership-with-polars-f4331031bac6',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2021-10-22T00:00:00Z',
    text: 'A new Roobee.finance update!',
    link: 'https://medium.com/@roobee_invest/a-new-roobee-finance-update-322dff731499',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2021-08-31T00:00:00Z',
    text: 'Roobee.finance has received an update!',
    link: 'https://medium.com/roobee-invest/%EF%B8%8F-roobee-finance-has-received-an-update-c11d295753dc',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2021-08-24T00:00:00Z',
    text: 'ROOBEE/USDT trading pair launch on Bitforex! 1,000,000 $ROOBEE to be airdropped!',
    link: 'https://medium.com/roobee-invest/roobee-usdt-trading-pa'
      + 'ir-launch-on-bitforex-1-000-000-roobee-to-be-airdropped-95a4605b252a',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2021-08-17T00:00:00Z',
    text: 'Information on Airbnb (UAIR)!',
    link: 'https://medium.com/roobee-invest/information-on-airbnb-uair-77bb39bb6596',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2021-08-12T00:00:00Z',
    text: 'Public Roobee DAO launch!',
    link: 'https://medium.com/roobee-invest/public-roobee-dao-launch-a48e2f26e8ae',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2021-08-02T00:00:00Z',
    text: 'A fresh Roobee.finance update',
    link: 'https://medium.com/roobee-invest/%EF%B8%8F-a-fresh-roobee-finance-update-e09ce9389cbe',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2021-07-27T00:00:00Z',
    text: 'ROOBEE staking on gate.io! 35% APR!',
    link: 'https://medium.com/roobee-invest/roobee-staking-on-gate-io-35-apr-dd6064ac8251',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2021-04-23T00:00:00Z',
    text: 'SPAC/IPO portfolio is now available for purchase!',
    link: 'https://medium.com/roobee-invest/spac-ipo-portfolio-is-now-available-for-purchase-5239fa2bea4d',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2021-04-22T00:00:00Z',
    text: 'Roobee.finance is live!',
    link: 'https://medium.com/roobee-invest/roobee-finance-is-live-a9ab82726958',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2021-04-20T00:00:00Z',
    text: 'A major Roobee update!',
    link: 'https://medium.com/roobee-invest/a-major-roobee-update-339f32021228',
  },

  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2021-03-25T00:00:00Z',
    text: 'The Trending Tech portfolio is now available on Roobee!',
    link: 'https://medium.com/roobee-invest/the-trending-tech-portfolio-is-now-available-on-roobee-645e7e9672fb',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2021-03-24T00:00:00Z',
    text: 'A major Roobee update!',
    link: 'https://medium.com/roobee-invest/a-major-roobee-update-da59dd7501dc',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2021-03-12T00:00:00Z',
    text: 'World premiere: InVision’s pre-IPO is now available on the Roobee platform!',
    link: 'https://medium.com/roobee-invest/world-premiere-invisions-pre-ipo-is-'
      + 'now-available-on-the-roobee-platform-a5242366b408',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2021-03-11T00:00:00Z',
    text: 'Roobee X Gnosis Safe',
    link: 'https://medium.com/roobee-invest/roobee-x-gnosis-safe-66d7113b93f2',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2021-03-01T00:00:00Z',
    text: 'Winners of Roobee Liquidity Carnival',
    link: 'https://medium.com/roobee-invest/winners-of-roobee-liquidity-carnival-75bfb5c2dede',
  },
  {
    avatar: '/assets/images/news/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2021-02-04T00:00:00Z',
    text: 'Why Roobee.finance and DeFi?',
    link: 'https://medium.com/roobee-invest/why-roobee-finance-and-defi-dc75f3cecfd7',
  },
];

export {
  NewsSlides,
};

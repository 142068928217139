import Hero from 'containers/Hero';
import Header from 'containers/Header';
import Networks from 'containers/Networks';
import DCA from 'containers/DCA';
import Dashboard from 'containers/Dashboard';
import Liqudity from 'containers/Liqudity';
import Save from 'containers/Save';
import Marketplace from 'containers/Marketplace';
import Wallet from 'containers/Wallet';
import Eco from 'containers/Eco';
import Footer from 'containers/Footer';
import News from 'containers/News';
import Tokens from 'containers/Tokens';
import Review from 'containers/Review';
import { roobeeTokenCards, roobeeTokenWebsites } from 'consts/tokens';
import { HeroContent } from 'consts/hero';

import { WalletContent } from 'consts/wallet';
import { NewsSlides } from 'consts/news';

import React, { FC } from 'react';

const App: FC = () => (
  <>
    <Header />
    <Hero data={HeroContent} />
    <Networks />
    <DCA />
    <Dashboard />
    <Liqudity />
    <Save />
    <Marketplace />
    <Wallet data={WalletContent} />
    <Eco />
    <Tokens roobeeTokenWebsites={roobeeTokenWebsites} roobeeTokenCards={roobeeTokenCards} />
    <Review />
    <News data={NewsSlides} />
    <Footer />
  </>
);
export default App;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Styled from './styled';

const Review:FC = () => {
  const { t } = useTranslation();
  return (
    <Styled.Wrapper>
      <Styled.Container>
        <Styled.Title>{t('REVIEW_title')}</Styled.Title>
        {/* eslint-disable-next-line max-len,jsx-a11y/iframe-has-title */}
        <iframe src="https://widgets.revain.org/carousel?sortBy=rating&perPage=12&bg=transparent&contentLines=4&writeReviewAt=5&reviewLocale=en&showRatingCard=false&boxShadow=elevation3&showMoreLinkColor=%231992d4&disableTelemetry=false&showSignUpHeader=false&reviewTargetSlug=projects%2Froobee&consumer=roobee.io" width="100%" height="500" style={{ border: 'none', display: 'block' }} />
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default Review;
